import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'

import Search from "./search"

import {addSpace, screenSizes} from '../utils/helpers'

const Header = styled.header`
	display: flex;
	height: 63px;
	background: #FFF;
	position: sticky;
	top: 0;
	z-index: 20;
	align-items: center;
	padding: 0 1.5rem;
`

const Knowledgebase = styled(Link)`
	margin-left: 1rem;
	display: inline-block;

	&:hover {
		text-decoration: none;
	}

	&:after {
		display: block;
		content: '';
		border-bottom: solid 3px var(--base-color);
		transform: scaleX(0);
		transition: transform 250ms ease-in-out;
	}
	&:hover:after {
		transform: scaleX(1);
	}
`

export default function Navbar({knowledgebases, children}){
	return(
		<Header>
			{children}
			{(typeof window === `undefined` || window.innerWidth > screenSizes.md) ? (<Search />) : ""}
			<nav style={{marginLeft: 'auto'}}>
				{knowledgebases.map(function(val, i){
					return(<Knowledgebase key={i} to={"/" + val.relativePath + "/"}>{addSpace(val.relativePath)}</Knowledgebase>)
				})}
			</nav>
		</Header>
	)
}