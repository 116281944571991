import React, {useState} from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'

import {addSpace, modularScale, breakpoints} from '../utils/helpers'
import parseSidebar from '../utils/parseSidebar'

const Home = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
		<path d="M570.69 236.28L512 184.45V48a16 16 0 0 0-16-16h-64a16 16 0 0 0-16 16v51.69L314.75 10.31a39.85 39.85 
		0 0 0-53.45 0l-256 226a16 16 0 0 0-1.21 22.6L25.5 282.7a16 16 0 0 0 22.6 1.21L277.42 81.63a16 16 0 0 1 21.17 0L527.91 
		283.9a16 16 0 0 0 22.6-1.21l21.4-23.82a16 16 0 0 0-1.22-22.59zM288 115L69.47 307.71c-1.62 1.46-3.69 2.14-5.47 3.35V496a16 
		16 0 0 0 16 16h128a16 16 0 0 0 16-16V368a16 16 0 0 1 16-16h96a16 16 0 0 1 16 16v128a16 16 0 0 0 16 16h128a16 16 0 0 0 
		16-16V311.1c-1.7-1.16-3.72-1.82-5.26-3.2z"/>
	</svg>
)

const AngleUp = () => (
	<svg style={{
			width: `${modularScale(-1)}`,
			marginLeft: `${modularScale(-1)}`,
			position: 'relative',
			fill: 'var(--base-color)',
			top: '-2px',
		}}
		xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
		<path d="M168.5 164.2l148 146.8c4.7 4.7 4.7 12.3 0 17l-19.8 19.8c-4.7 
		4.7-12.3 4.7-17 0L160 229.3 40.3 347.8c-4.7 4.7-12.3 4.7-17 0L3.5 
		328c-4.7-4.7-4.7-12.3 0-17l148-146.8c4.7-4.7 12.3-4.7 17 0z"/>
	</svg>
)

const AngleDown = () => (
	<svg style={{
			width: `${modularScale(-1)}`,
			marginLeft: `${modularScale(-1)}`,
			position: 'relative',
			fill: 'var(--base-color)',
			top: '-2px',
		}}
		xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
		<path d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 
		12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 
		4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z"/>
	</svg>
)

const Side = styled.aside`
	flex-shrink: 0;
	width: 305px;
	height: 100%;
	overflow-y: auto;
	position: relative;
	border-right: 1px solid #bfd0dc;
	a {
		color: rgba(0,0,0,0.60);
		&:hover {
			color: var(--base-color);
		}
	}

	ul li {
		margin-bottom: .725rem;
		margin-left: 0;
	}

	div {
		padding: 1rem 2rem;
		border-bottom: 1px solid #bfd0dc;

		:last-child {
			border-bottom: none;
		}
	}

	${breakpoints.lg} {
		transform: ${props => props.toggle ? "translateX(0)": "translateX(-100%)"};
		transition: all 300ms ease-in-out;
		z-index: 50;
		box-shadow: ${props => props.toggle ? "rgba(0, 0, 0, 0.25) 0px 0px 48px" : ""};
		position: absolute;
		top: 0;
		background: #FFF;
	}
`

const Header = styled.header`
	padding: 1rem;
	border-bottom: 1px solid #bfd0dc;
	font-size: 1.25rem;
	position: sticky;
	top: 0;
	z-index: 1;
	background: #FFF;
	display: flex;
	align-items: center;

	a {
		opacity: 1;
		color: var(--base-color);
	}

	svg {
		width: ${modularScale(3)};
		margin-bottom: 0;
		margin-top: -5px;
		margin-right: ${modularScale(-4)};
		fill: var(--base-color);
		background: #FFF;
		padding: 5px;
		border-radius: 4px;

		&:hover {
			fill: #FFF;
			background: var(--base-color);
		}
	}
`

const Section = styled.h5`
	padding: 1rem 0;
	border: 0;
	margin: 0;
	letter-spacing: 2px;
	color: rgb(0,0,0,0.87);

	&:hover {
		color: var(--base-color);
	}

	img {
		max-width: 10%;
	}
`

const Page = ({url, title, isCurrentPage, style}) => (
	(isCurrentPage) ? (
		<li style={style} >
			<Link style={{color: 'var(--base-color)', opacity: 1}} to={url}>{title}</Link>
		</li>
	) : (
		<li style={style}>
			<Link to={url}>{title}</Link>
		</li>
	)
)

function Category({title, pages, url}){
	let index = pages.findIndex(el => el.url === url)
	const [toggle, setToggle] = useState((index > -1))

	let subs = pages.map(function(val, key){
		return(<Page key={key} title={addSpace(val.title)} url={val.url} isCurrentPage={(url === val.url)} style={{marginLeft: `${modularScale(0)}`}} />)
	})

	return(
			<li>
				<span onClick={() => setToggle(!toggle)}>
					{addSpace(title)}
					{(toggle) ? (<AngleUp />) : (<AngleDown />)}
				</span>
				{(toggle) ? (<ul style={{listStyle: 'none'}}>{subs}</ul>) : (<ul></ul>) }
			</li>
	)
}

export default function Sidebar({kb, url, category, toggle, content}){
	return(
		<Side toggle={toggle}>
			<Header>
				<Link to={'/'}><Home /></Link>
				<Link style={{}} to={'/' + kb + '/'}>{addSpace(kb)}</Link>
			</Header>
			{parseSidebar(content).map(function(section, i){

				let pages = section.pages.map(function(val, key){
					if(val.sub){
						return(<Category key={key} title={val.sub} pages={val.pages} url={url} />)
					}
					return(<Page key={key} title={addSpace(val.title)} url={val.url} isCurrentPage={(url === val.url)} />)
				})

				return(
					<div key={i}>
						<Link to={section.pages[0].url}>
							<Section style={(category != null && section.category === addSpace(category)) ? {color: `var(--base-color)`, opacity: 1} : {}}>
								{section.category.toUpperCase()}
							</Section>
						</Link>
						<ul style={{listStyle: 'none', margin: 0}}>{pages}</ul>
					</div>
				)
			})}
		</Side>
	)
}