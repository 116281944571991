import React, {useState} from "react"
import {Link} from "gatsby"
import {InstantSearch, connectStateResults, connectSearchBox, connectHits, Highlight, Snippet} from 'react-instantsearch-dom'
import algoliasearch from "algoliasearch/lite"
import styled from 'styled-components'

import {addSpace} from '../utils/helpers'
import {SearchIcon, Background} from '../styles/styledComps'

const ResultBox = styled.div`
	position: absolute;
	overflow-y: auto;
	height: 42rem;
	z-index: 1;
	top: 100%;
	width: 42rem;
	border: 1px solid #959DAA;
	border-radius: 4px;
	background: white;
	margin-top: 1rem;
	box-shadow: rgba(0,0,0,0.25) 0 2px 12px;

	div {
		padding: 1rem;
		border-bottom: 1px solid rgba(0,0,0,0.38);

		a {
			text-decoration: none;
		}

		&:hover {
			background: #EEE;
		}
	}

	p {
		margin: 0;
	}

	h4 {
		margin-bottom: 0;
		color: var(--base-color);
	}

	h6 {
		margin-bottom: .5rem;
		color: var(--base-color);
	}
`

const SearchBox = styled.div`
	border-radius: 4px;
	width: 100%;
	background: var(--base-color);
	color: white;
	flex-grow: 1;
	margin-left: 2rem;
	max-width: 500px;
	z-index: 1;
	border: 1px solid var(--base-color);
	transition: box-shadow 150ms ease-in-out;
`

const SearchInput = connectSearchBox(({ currentRefinement, refine }) => (
	<>
	<SearchIcon  />
	<input type="text" 
		placeholder="Search" 
		aria-label="Search"
		value={currentRefinement} 
		onChange={e => refine(e.target.value)}
		style={{border: 'none', width: '94%', outline: 'none'}}
	/>
	</>
))

const HitComp = connectHits(({ hits, query }) => (
	<ResultBox>
		{hits.slice(0, 10).map(hit => (
			<div key={hit.objectID}><Link to={hit.url}>
				<h4><Highlight attribute="title" hit={hit} tagName="mark" /></h4>
				<h6><span style={{color: 'black', fontWeight: 400,}}>in</span> {addSpace(hit.kb)}</h6>
				<p style={{color: '#212121'}}><Snippet attribute="content" hit={hit} tagName="mark" /></p>
			</Link></div>
		))}
		<Link to={'/search'} state={{query: query}}><div style={{border: 'none'}}>See More Results</div></Link>
	</ResultBox>
))

const Results = connectStateResults(
	({ searchState: state, searchResults: res, focus }) => {
		if(state.hasOwnProperty('query')){
			if(state.query !== '' && focus){
				if(res && res.nbHits > 0){
					return(<HitComp query={state.query} />)
				}
				return(<ResultBox><p style={{margin: '1rem', color: '#212121'}}>No results found for <span style={{color: '#15537f' }}>{state.query}</span></p></ResultBox>)
			}
		}
		return(``)
	}
)

export default function Search(){
	const [focus, setFocus] = useState(false);
	return (
		<>
		<Background hide={!focus} index={1} onClick={() => setFocus(false)} />
		<InstantSearch
			searchClient={algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY)}
			indexName={process.env.GATSBY_INDEX}
			onSearchStateChange={({query}) => (query === '') ? setFocus(false) : setFocus(true)}
			root={{ Root: SearchBox }}
		>
			<SearchInput />
			<Results focus={focus} />
		</InstantSearch>
		</>
	)
}
