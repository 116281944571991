import { addSpace } from './helpers'

export default function parseSidebar(data){
	let subs = data.filter(el => el.fields.sub !== null).reduce((acc, cv, index) => {
		let pointer = acc.findIndex(el => el.sub === addSpace(cv.fields.sub))
		let data = { title: cv.frontmatter.title, url: cv.fields.url }

		if(pointer > -1){
			acc[pointer].pages.push(data)
		} else {
			acc.push({ 
				category: addSpace(cv.fields.category), 
				sub: addSpace(cv.fields.sub), 
				order: cv.fields.order,
				categoryOrder: cv.fields.categoryOrder, 
				pages:[data] 
			})
		}
		return acc
	}, [])
		
	let content = data.filter(el => el.fields.sub === null).reduce((acc, cv, index) => {
		let pointer = acc.findIndex(el => el.category === addSpace(cv.fields.category))
		let data = { title: cv.frontmatter.title, url: cv.fields.url, order: cv.frontmatter.order }

		if(pointer > -1){
			acc[pointer].pages.push(data)
		} else  {
			acc.push({ category: addSpace(cv.fields.category), order: cv.fields.order, pages: [data] })
		}

		return acc
	}, [])

	subs.forEach((sub, index) => {
		let value = content.filter(el => el.category === sub.category)
		if(value.length > 0){
			value[0].pages.push(sub)
		} else {
			content.push({
				category: addSpace(sub.category),
				order: sub.categoryOrder,
				pages: [sub]
			})
		}
	})

	content.sort((a, b) => a.order - b.order);
	content.forEach(({pages}) => pages.sort((a, b) => a.order - b.order))

	return(content)
}